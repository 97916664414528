var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "percentage-chart-wrapper" }, [
    _vm.title
      ? _c("div", { staticClass: "title" }, [
          _c("p", [_vm._v(_vm._s(_vm.title))]),
        ])
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "percentage-chart",
        style: _vm.makeWrapperStyles(_vm.height),
      },
      _vm._l(_vm.blocks, function (block, inx) {
        return _c("div", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: `${block.label} - ${block.width}%`,
              expression: "`${block.label} - ${block.width}%`",
            },
          ],
          key: inx,
          staticClass: "inner",
          style: _vm.makeDimens(block),
        })
      }),
      0
    ),
    _vm.showLegend
      ? _c(
          "div",
          { staticClass: "legend" },
          _vm._l(_vm.blocks, function (block, inx) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: `${Math.round(block.width)}% (${block.value})`,
                    expression:
                      "`${Math.round(block.width)}% (${block.value})`",
                  },
                ],
                key: inx,
                staticClass: "legend-item",
              },
              [
                block.label
                  ? _c("div", {
                      staticClass: "dot",
                      style: _vm.makeDotColor(block),
                    })
                  : _vm._e(),
                block.label
                  ? _c("div", { staticClass: "txt" }, [
                      _vm._v(_vm._s(block.label)),
                    ])
                  : _vm._e(),
              ]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }